import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  dateCreated: string
  description?: string
  image?: ImageProps
  languageCode: string
  title: string
  URL: string
}

export const Offer = memo(function Offer({
  description,
  image,
  languageCode,
  title,
  URL,
}: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container to={URL}>
      <FadeInUp>
        {image ? <Image {...image} /> : null}
        <Wrapper dial={7} row space="between" wrap>
          <Text>
            <Title>{title}</Title>
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
          </Text>
          <CTA
            className="offer-button"
            label={useVocabularyData('find-out-more', languageCode)}
            noActions
            variant="S"
          />
        </Wrapper>
      </FadeInUp>
    </Container>
  )
})

const Container = styled(Link)`
  width: calc(43% - 3rem);
  margin: 3rem 3rem 0 0;
  &:nth-of-type(4n + 2),
  &:nth-of-type(4n + 3) {
    width: calc(57% - 3rem);
  }

  > div {
    height: 27.778vw;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    overflow: hidden;
    position: relative;
    &:hover {
      &:before {
        opacity: 0.8;
      }
      &:after {
        opacity: 1;
      }
      .offer-button {
        &:before {
          background: ${({ theme }) => theme.colors.variants.neutralLight3};
          right: 0;
        }
        span {
          color: ${({ theme }) => theme.colors.variants.primaryLight1};
          transform: translateY(-0.5rem);
          &:after {
            right: 0.0625rem;
            transform: translateY(0.5rem);
          }
        }
      }
      img {
        transform: scale(1.1);
      }
    }
    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.2s ease-in-out;
      z-index: 2;
    }
    &:before {
      background: ${({ theme }) => theme.colors.variants.neutralDark1};
      opacity: 0.5;
    }
    &:after {
      border: 0.625rem solid
        ${({ theme }) => theme.colors.variants.neutralLight4};
      opacity: 0;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.4s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: 100% !important;
    margin-right: 0;
    &:nth-of-type(even) {
      width: 100%;
    }
    > div {
      height: auto;
    }
  }
`

const Wrapper = styled(FlexBox)`
  position: absolute;
  bottom: 2.5rem;
  right: 2.5rem;
  left: 2.5rem;
  z-index: 2;

  @media (max-width: 1023px) {
    padding: 3rem 6.039vw;
    position: relative;
    bottom: auto;
    right: auto;
    left: auto;
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  flex: 1;
  padding-right: 2.5rem;

  @media (max-width: 1023px) {
    width: 100%;
    flex: auto;
    padding-right: 0;
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-transform: uppercase;
`

const Description = styled.div`
  display: -webkit-box;
  height: 2.9375rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 1.5625rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 1023px) {
    margin-top: 1rem;
  }
`

const CTA = styled(Button)`
  border-color: ${({ theme }) => theme.colors.variants.neutralLight3};
  &:before {
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
  }

  span {
    color: ${({ theme }) => theme.colors.variants.neutralLight3};
    &:after {
      background: ${({ theme }) => theme.colors.variants.primaryLight1};
    }
  }

  @media (max-width: 1023px) {
    margin-top: 3.125rem;
  }
`
